<template>
  <div class="over">
    <div class="content">
      <div class="contentitem">
        <div class="title">{{ $t("top.Learn") }}</div>
        <div class="title1" @click="toPath('home')">{{ $t("bottom.Basics") }}</div>
        <div class="title1" @click="toPath('Basics')">{{ $t("bottom.CyberDAOwhat") }}</div>
        <div class="title1" @click="toPath('Background')">{{ $t("bottom.Background") }}</div>
        <!-- <div class="title1" @click="toPath('skynet')">{{ $t("bottom.Consultants") }}</div> -->
        <div class="title1" @click="toPath('Partners')">{{ $t("bottom.Partners") }}</div>
        <div class="title1" @click="toPath('CyberDAO')">{{ $t("bottom.CyberDAO") }}</div>
      </div>
      <div class="contentitem">
        <div class="title">{{ $t("bottom.Governance") }}</div>
        <div class="title1" @click="toPath('Protocol')">{{ $t("bottom.Protocol") }}</div>
        <!-- <div class="title1">{{ $t("bottom.POD") }}</div> -->
        <div class="title1" @click="toPath('Token')">{{ $t("bottom.Token") }}</div>
        <div class="title1" @click="toPath('CyberDAOPool')">{{ $t("bottom.Pool") }}</div>
        <div class="title1" @click="toPath('global')">{{ $t("bottom.Nodes") }}</div>
        <!-- <div class="title1" @click="toPath('Vote')">{{ $t("bottom.Vote") }}</div> -->
      </div>
      <div class="contentitem">
        <div class="title">{{ $t("bottom.Ecosystem") }}</div>
        <div class="title1" @click="toPath('ecosystems')">{{ $t("bottom.Wallet") }}</div>
        <div class="title1" @click="toPath('Sapce')">{{ $t("bottom.Sapce") }}</div>
        <div class="title1">{{ $t("bottom.Grantsl") }}</div>
      </div>
      <div class="contentitem">
        <div class="title">{{ $t("bottom.Foundation") }}</div>
        <div class="title1" @click="toPath('Contact')">{{ $t("bottom.Contact") }}</div>
        <div class="title1">{{ $t("bottom.Opportunities") }}</div>
      </div>
    </div>
    <div class="Newsletter">
      <img src="../assets/img/page2/Layer20.png" alt />
      <div class="flexs">
        <input v-model="value"  :placeholder="$t('bottom.email')" class="feild alipay-font"  />
        <div class="submit" @click="submit">{{ $t("bottom.Newsletter") }}</div>

      </div>
       <!-- <p v-if="error">{{ error }}</p> -->
       
      <!-- <p>{{ $t("bottom.Newsletter") }}</p> -->
      <div class="button" @click="selectLanguage()">{{ $i18n.locale == "zh-CN" ? "English" : "中文" }}</div>
    </div>
  </div>
</template>

<script>

import {subscribe} from '../api/home'
import i18n from '@/utils/lang'
import { Field,Toast } from 'vant';
export default {
  components:{
  [Field.name]: Field.Component,
   [Toast.name]: Toast,
  },
  data() {
    return {
       value: '',
       error: ''  
    };
  },
   
  methods: {
     validateEmail() {  
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;  
      if (!emailRegex.test(this.value)) {  
        this.error = i18n.t('bottom.submit');  
      } else {  
        this.error = '';  
      }  
    } , 
    submit(){
       const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;  
      if (!emailRegex.test(this.value)) {  
        Toast(i18n.t('bottom.submit')); 
      } else {  
        let params = {
          email:this.value
        }
        subscribe(params).then((res)=>{
         if(!res.code){
          this.value = ''
          Toast(i18n.t('bottom.sucess')); 
         }
        })
      }  

    },
    selectLanguage() {
      if (localStorage.getItem("languageSet") == "en") {
        this.$i18n.locale = "zh-CN";
        localStorage.setItem("languageSet", "zh-CN");
        this.$emit("changeLang", "0");
      } else {
        this.$i18n.locale = "en";
        localStorage.setItem("languageSet", "en");
        this.$emit("changeLang", "1");
      }
    },
    toPath(val) {
      if (val == "home") {
        if (window.location.href.split("/")[3] == "") {
          this.$emit("scrollToSections", val);
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "abouts", params: { Basics: val } });
        }
      }

      if (val == "Basics") {
        if (window.location.href.split("/")[3] == "") {
          this.$emit("scrollToSections", val);
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "abouts", params: { Basics: val } });
        }
      }
      if (val == "Background") {
        if (window.location.href.split("/")[3] == "") {
          this.$emit("scrollToSections", val);
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "abouts", params: { Basics: val } });
        }
      }
      if (val == "skynet") {
        if (window.location.href.split("/")[3] == "") {
          this.$emit("scrollToSection", val);
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "abouts", params: { Basics: val } });
        }
      }
      if (val == "Partners") {
        if (window.location.href.split("/")[3] == "") {
          this.$emit("scrollToSections", val);
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "abouts", params: { Basics: val } });
        }
      }
      if (val == "CyberDAO") {
       window.open('https://dao.cyber-dao.com')
      }

      if (val == "Protocol") {
        if (window.location.href.split("/")[3] == "covemances") {
          this.$emit("scrollToSection", val);
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "covemances", params: { Basics: val } });
        }
      }

      if (val == "Token") {
        if (window.location.href.split("/")[3] == "govemances") {
          this.$emit("scrollToSection", val);
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "govemances", params: { Basics: val } });
        }
      }

      if (val == "CyberDAOPool") {
        if (window.location.href.split("/")[3] == "govemances") {
          this.$emit("scrollToSection", val);
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "govemances", params: { Basics: val } });
        }
      }
      if (val == "global") {
        if (window.location.href.split("/")[3] == "govemances") {
          this.$emit("scrollToSection", val);
        } else {
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "govemances", params: { Basics: val } });
        }
      }

      //   if (val == "Vote") {
      //     if (window.location.href.split("/")[3] == "stakes") {
      //     this.$emit("scrollToSection", val);
      //     } else {

      //       // 非首页时跳转到首页的The Basics指定位置
      //       this.$router.push({ name: "stakes", params: { Basics: val } });
      //     }
      //   }
      if (val == "ecosystems") {
        if (window.location.href.split("/")[3] == "ecosystems") {
          this.$emit("scrollToSection", val);
        } else {
          this.show = false;
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "ecosystems", params: { Basics: val } });
        }
      }
      if (val == "Sapce") {
        if (window.location.href.split("/")[3] == "ecosystems") {
          this.$emit("scrollToSection", val);
        } else {
          this.show = false;
          // 非首页时跳转到首页的The Basics指定位置
          this.$router.push({ name: "ecosystems", params: { Basics: val } });
        }
      }
      if (val == "Contact") {
         window.open('mailto:support@cyber-dao.com')
      }
    }
    // selectLanguages() {
    //   this.$i18n.locale = "en";
    //   localStorage.setItem("languageSet", "en");
    //   this.$emit("childrefresh", "1");
    // },
  }
};
</script>

<style lang="less" scoped>
@fontface {  
  font-family: 'AlibabaFangYuan';  
  src: url('~@/assets/inconfont.ttf') format('truetype');  
}
.alipay-font {  
  font-family: 'AlibabaFangYuan', sans-serif;  
}
.over {
  position: relative;
  width: 100%;
  height: 80%;
  background: #080935;
  padding: 10%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .content {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .contentitem {
      width: fit-content;
      margin-right: 5%;
      .title {
        width: fit-content;
        font-size: 34Px;
        font-family: Oxanium;
        font-weight: 500;
        color: #ffffff;
        // line-height: 33Px;
        margin-bottom: 30Px;
      }
      .title1 {
        width: fit-content;
        height: fit-content;
        font-size: 24Px;
        font-family: Oxanium;
        font-weight: 400;
        color: #ccc;
        line-height: 58Px;
        cursor: pointer;
      }
    }
  }
  .Newsletter {
    width: 20%;
    position: absolute;
    right: 10%;
    top: -30Px;
    img {
      width: 80%;
      height: 80%;
    }

    p {
      margin-top: 2%;
      margin-right: 37%;
      font-size: 24Px;
      font-family: DINPro;
      // font-weight: bold;
      color: red;
      text-align: center;
    }
    .flexs{
      display: flex;
      margin: 30Px 0 0 30Px;
       .feild{
      width: 239Px;
      height: 48Px;
      background-color: #fff;
      border-radius: 10Px;
      padding: 16Px 31Px;
      font-size: 20Px;
      // font-family: Oxanium-Regular;
      box-sizing: border-box;
      color: #616161;
    }
    .submit{
      width: fit-content;
      height: 48Px;
      background-color: #4067ED;
      color: #fff;
      font-size: 20Px;
      border-radius: 10Px;
      padding: 14Px 19Px;
      box-sizing: border-box;
      margin-left: 14Px;
      cursor: pointer;
    }

    }
   
    .button {
      width: 240Px;
      height: 53Px;
      border: 2Px solid #ffffff;
      border-radius: 20Px;
      line-height: 53Px;
      font-size: 23Px;
      font-family: Oxanium;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      position: absolute;
      right: 20%;
      bottom: -60%;
      cursor: pointer;
    }
  }
}
</style>
