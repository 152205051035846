/*
 * @Author: qinlifang 1171533972@qq.com
 * @Date: 2023-11-09 20:11:07
 * @LastEditors: 1171533972@qq.com
 * @LastEditTime: 2023-11-14 17:04:14
 * @FilePath: \cyberdao-home\src\utils\devicePixelRatio.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

// class DevicePixelRatio {
//     constructor() {
//             // this.flag = false;
//         }
//         // 获取系统类型
//     _getSystem() {
//             // let flag = false;
//             var agent = navigator.userAgent.toLowerCase();
//             //		var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
//             //		if(isMac) {
//             //			return false;
//             //		}
//             // 现只针对windows处理，其它系统暂无该情况，如有，继续在此添加
//             if (agent.indexOf('windows') >= 0) {
//                 return true;
//             }
//         }
//         // 获取页面缩放比例
//         //	_getDevicePixelRatio() {
//         //		let t = this;
//         //	}
//         // 监听方法兼容写法
//     _addHandler(element, type, handler) {
//             if (element.addEventListener) {
//                 element.addEventListener(type, handler, false);
//             } else if (element.attachEvent) {
//                 element.attachEvent('on' + type, handler);
//             } else {
//                 element['on' + type] = handler;
//             }
//         }
//         // 校正浏览器缩放比例
//     _correct() {
//         var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

//         let t = this;
//         // 页面devicePixelRatio（设备像素比例）变化后，计算页面body标签zoom修改其大小，来抵消devicePixelRatio带来的变化。
//         if (windowWidth < 2755) {
//             document.getElementsByTagName('body')[0].style.zoom = 0.5 / window.devicePixelRatio;
//         } else {
//             document.getElementsByTagName('body')[0].style.zoom = 1 / window.devicePixelRatio;
//         }
//     }

//     // 监听页面缩放
//     _watch() {
//             let t = this;
//             t._addHandler(window, 'resize', function() { // 注意这个方法是解决全局有两个window.resize
//                 // 重新校正
//                 t._correct()
//             })
//         }
//         // 初始化页面比例
//     init() {
//         let t = this;
//         if (t._getSystem()) { // 判断设备，目前只在windows系统下校正浏览器缩放比例
//             // 初始化页面校正浏览器缩放比例
//             t._correct();
//             // 开启监听页面缩放
//             t._watch();
//         }
//     }
// }
// export default DevicePixelRatio;
class DevicePixelRatio {
    // 获取系统类型  
    _getSystem() {
        var agent = navigator.userAgent.toLowerCase();
        // 判断是否为 Mac OS（不区分大小写）  
        if (agent.indexOf('macintosh') >= 0 || agent.indexOf('mac os x') >= 0) {
            return "macOS";
        }
        // 只针对windows处理，其它系统暂无该情况，如有，继续在此添加  
        if (agent.indexOf('windows') >= 0) {
            return "Windows";
        }
        // 判断是否为 iOS（不区分大小写）  
        if (agent.indexOf('iphone') >= 0 || agent.indexOf('ipad') >= 0 || agent.indexOf('ipod') >= 0) {
            return "iOS";
        }
        // 判断是否为 Android（不区分大小写）  
        if (agent.indexOf('android') >= 0) {
            return "Android";
        }
        // 其他情况，返回 false 或其他你认为合适的值  
        return false;
    }

    // 获取页面缩放比例  
    // _getDevicePixelRatio() {  
    //     let t = this;  
    // }  
    // 监听方法兼容写法  
    _addHandler(element, type, handler) {
            if (element.addEventListener) {
                element.addEventListener(type, handler, false);
            } else if (element.attachEvent) {
                element.attachEvent('on' + type, handler);
            } else {
                element['on' + type] = handler;
            }
        }
        // 校正浏览器缩放比例  
    _correct() {
            var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            console.log(123, this._getSystem(), windowWidth, window.screen.width);
            let t = this;
            if (t._getSystem() === 'macOS' && windowWidth > 1512) {
                console.log(windowWidth, 'windowWidth');
                document.getElementsByTagName('body')[0].style.zoom = 1 / window.devicePixelRatio;
            } else if (t._getSystem() === 'Windows' && windowWidth == 1920) {
                document.getElementsByTagName('body')[0].style.zoom = 0.9 / window.devicePixelRatio;
            } else if (t._getSystem() === 'Windows' && windowWidth > 1920) {
                document.getElementsByTagName('body')[0].style.zoom = 1 / window.devicePixelRatio;
            } else if (t._getSystem() === 'Windows' && windowWidth < 1512) {
                document.getElementsByTagName('body')[0].style.zoom = 0.7 / window.devicePixelRatio;
            } else if (t._getSystem() === 'Windows' && windowWidth >= 1707 && windowWidth < 1920) {
                document.getElementsByTagName('body')[0].style.zoom = 0.7 / window.devicePixelRatio;
            }
        }
        // 监听页面缩放  
    _watch() {
            let t = this;
            t._addHandler(window, 'resize', function() { // 注意这个方法是解决全局有两个window.resize的问题。如果还有其他问题，请自行解决。  
                // 重新校正。如果还有其他需要监听的事件，请自行添加。  
                t._correct();
            });
        }
        // 初始化页面比例  
    init() {
        let t = this;
        if (t._getSystem()) { // 判断设备，目前只在windows系统下校正浏览器缩放比例，如果还有其他系统需要校正，请自行添加。如果有其他功能需要初始化，请自行添加。  
            // 初始化页面校正浏览器缩放比例。如果有其他功能需要初始化，请自行添加。  
            t._correct();
            // 开启监听页面缩放。如果有其他功能需要初始化，请自行添加。  
            t._watch();
        } else {}
    }
}
export default DevicePixelRatio;