import request from "@/utils/request"; // 上述封装的js

//官网Pool数据
export function getProject(params) {
    return request({
        url: "dao/pool",
        method: "get",
        params,
    });
}
//邮件
export function subscribe(data) {
    return request({
        url: "dao/subscribe",
        method: "post",
        data,
    });
}