import { render, staticRenderFns } from "./Over.vue?vue&type=template&id=048abc70&scoped=true"
import script from "./Over.vue?vue&type=script&lang=js"
export * from "./Over.vue?vue&type=script&lang=js"
import style0 from "./Over.vue?vue&type=style&index=0&id=048abc70&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "048abc70",
  null
  
)

export default component.exports