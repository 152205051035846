export default {
    web3: {
        no_install: '没有安装钱包',
        link_error: {
            waiting: '连接等待确认中，请检查您的钱包软件以确认',
            reject: '您已取消连接',
            fail: '连接失败',
        },
        sign_error: {
            reject: '您已取消签名',
            fail: '签名失败',
        },
        logout_title: '是否断开钱包连接？',
        logouted: '您已断开钱包的连接！',
    },
    top: {
        Learn: '关于我们',
        Governance: '治理',
        Ecosystem: '生态',
        Foundation: '基金会',
        Docs: '文档',
    },
    bottom: {
        Learn: '关于我们',
        Basics: "首页",
        CyberDAOwhat: "CyberDAO是什么",
        Background: "背景",
        Consultants: "顾问",
        Partners: "合作伙伴",
        CyberDAO: "CyberDAO Dapp",
        Governance: "治理",
        Protocol: "空间协议(POD)",
        POD: "(付款)",
        Token: "CC代币",
        Pool: "矿池",
        Nodes: "节点",
        Vote: "投票",
        Ecosystem: "生态",
        Wallet: "Era钱包",
        Sapce: "Web3Space",
        Grantsl: "申请Grant ",
        Foundation: "基金会",
        Contact: "联系我们",
        Opportunities: "工作机会 ",
        Newsletter: "订阅",
        English: "English  ",
        Chinese: '中文',
        email: "您的电子邮件地址",
        submit: '请输入正确的邮箱地址',
        sucess: '订阅成功'
    },
    page1: {
        Staking: "空间协议（POD）质押",
        Interactive: "是用户约定质押条款，组队参与，根据CP比例赚取每日空投CC，协商质押总金额、资金比例、质押期限的互动实践。",
        Users: " 用户自主协商质押条件， 组队参与， 参与的人均可赚取CP值， 每日根据CP比例获得CC空投。 参与时需要讨论总质押量、 资金比例、 质押期限  ",
        READ: "了解更多",
        Stake: "质押量",
        Starts: "启动",
        FORM: '起',
        FUNDING: "资金比例",
        PERIOD: "质押期限",
        Days: "天",
        LAUNCH: "前往DApp",
    },
    learn: {
        Basics: "CyberDAO基础知识",
        DAD: "CyberDAO是一个致力于培育Web3精英和引导下一批十亿用户进入Web3时代的DAO组织。通过丰富的行业资源、专业的技术人才和创新的商业模式,我们的目标是为社区带来多样化的Web3产品、服务和商业机会,这可以对整个行业产生真正的影响,并确保DAO成员获得这个时代的回报。",
        Unique: "CyberDAO有何独特之处？ ",
        CyberDAO: "CyberDAO 坚持“一个 DAO 引导，多个 DAO 协作”的原则。 旨在以去中心化治理的方式构建一个积极主动的子DAO。 CyberDAO的最终形态是一个开放的、大规模的Web3商业应用，称为“CyberWorld”，在web3环境中创建一个网络世界。",
        TechDAO: "Tech DAO",
        MediaDAO: "Media DAO",
        EducationDAO: "Education DAO",
        ActivityDAO: "Activity DAO",
        FoundationDAO: "Foundation DAO",
        CharityDAO: "Charity DAO",
        ResearchDAO: "Research DAO",
        Taking: "CyberDAO以天、地、人网为CyberWorld的主要形态，构建了空前繁荣的WEB3商业应用生态系统。 ",
        SKYNET: "天网",
        LEARNMORE: "了解更多",
        Erais: "一款专为社区设计的Web3社交钱包",
        EARTHNET: "地网",
        Web3Space: "Web3Space是Web3的线下入口，旨在打造可视化的Web3空间 。",
        HUMANNET: "人网",
        Cultivate: "培养10万名Web3社区精英成员。",

    },
    Governance1: {
        Cyberspace: "空间协议是一种创新的共识合约，个人在其中结对建立联系。 随着社交网络上连接人数的增加，个体之间的联系将变得更加紧密，从而导致新。",
        LEARNMORE: "了解更多",
        Proof: "空间协议的贡献证明（POD）创新性地利用 POS 原理，通过社交图谱创建充满活力的共识网络。 这反过来又引导 CyberDAO 朝着协调和改进共识机制的方向发展。",
        Readmore: "白皮书",
        POD: "POD共识机制基于智能合约运行，并使用Facebook的EdgeRank算法创建去中心化共识网络。",
        Stakingcc: "质押 CC 要求用户与好友建立网络链接，设置决定其共识网络影响力的分红和持续时间， 每日 CC 奖励根据用户占总算力的份额进行分配。",
        Highlights: "空间协议的亮点",
        FirstPOD: "首创POD共识机制",
        SocialInnovation: "社会创新",
        ConsensusInnovation: "共识创新",
        SupernodeCompetition: "去中心化社交网络",
        FPODdetail: "将分层社交互动转变为 DeFi 共识网络， 彻底改变市场。",
        Socialtail: "在社交、生态、DAO 和治理领域都很有用，使内容创作者、 贡献者和活动家能够通过社会行动积累影响力并获得奖励。 ",
        Consensustail: "最早用于 CC 抵押产品，用户相互连接，赚取贡献点(CP)， 并获得与网络内个人CP值成比例的激励。 ",
        Supernodetail: "成功建立链路可自动成为好友，随着链路数不断增加，最终形成有影响力的去中心化社交网络。 ",

    },
    Governance2: {
        Token: "CyberDao治理代币 - CyberCoin (CC)",
        CCwhat: "CC是CyberDAO中流通的价值载体，生态应用和协议的使用都需要CC。 CC的持有量代表了用户在生态中的治理权重，可用于在生态中进行提案和投票。 CC的价值不仅依赖于CvberDAO生态的超级私域流量，还依赖于生态孵化合作的各类优质项目的数字资产。",
        Team: "团队",
        EcoFoundation: "生态基金",
        Investments: "投资人",
        PODOutput: "POD产出",
        TotolcCsupply: "CC供应量 - ",
        ValueOfCC: "CC的价值·由社区共识决定",
        Membership: "个会员",
        Become: "成为DAO成员，成为生态系统的一部分",
        Completely: "完全由您掌控，立即享受会员福利。",
        ReadMore: "更多信息",
        Governance: "治理",
        decentralized: "由 CC 持有者组成的去中心化社区管理CyberDAO 的智能合约协议。 ",
        constantly: "不断增长的 生态系统社区",
        Individuals: "参与去中心化治理的个人和组织，构建服务和应用程序的个人和组织，提供流量和服务的个人和组织。 ",
        totaloutput: "CC流通量",
        million: "万",
        CyberspacePOD: "空间协议POD",
        amount: "CC质押量超过",
        Applications: "CC持有者的申请和权利",
        CyberSpace: "空间协议",
        PODConsensus: "POD共识机制流量裂变赚取更多CC。 ",
        CyberZodiac: "CyberZodiac NFT",
        Symbol: "身份的象征利息累积稀缺性和收藏价值。 ",
        CyberDAOMember: "DAO治理",
        Soulbound: "CyberDAO成员和治理Soulbound代币提案权投票权。 ",
        EcoProjects: "生态项目",
        HighQuality: "优质项目空投生态项目授予特殊权利映射生态基金资产。",
        CyberDAOPool: "CyberDAO Pool",
        TheCyberDAO: "CyberDAO Pool提供了 CyberDAO 运行状态的全面视图。 该池作为一个广泛的概述，提供了对 CyberDAO 的重要见解。 对于内部和外部用户来说，它都是一个有价值的信息工具。",
        GLOBALAMOUNT: "全网总质押量",
        GLOBALLINKS: "全网总地址数",
        TOTALCP: "总CP值",
        TOTAL: "总计",
        DAYS: "天",
        SUPERNODES: "去中心化社交网络",
        LAUNCHAPP: "前往DApp",
    },
    Ecosystem: {
        Web3social: "专为社区打造的 Web3 社交钱包。 成为数十亿用户的Web3社交选择。",
        READMORE: "了解更多",
        Featureenhanced: "功能增强的Web3社交钱包",
        EraCryptocurrency: "Era加密货币钱包",
        EOA: "EOA、MPC、ERC4337钱包，不接触私钥，不存在泄露风险。 可以通过短信/邮件验证码通过协议私钥片段恢复钱包。说告别因私钥泄露而不得不更换钱包的问题。",
        EraSwap: "Era Swap",
        Inthemulti: "在多链时代，用户可以通过EraSwap交易多条链上的所有资产。 您只需要一个钱包地址，即可享受去中心化交易的便利，无需担心隐私问题。 Era Swap透明显示与交易相关的所有费用，并且不保留报价与实际价格之间的差异。",
        AIApplication: "人工智能应用",
        Basedon: "基于ChatGPT插件，Era提供了多种AI应用软件，释放用户的想象力和创造力。",
        DeFiAggregator: "DeFi聚合器",
        EraSwapintegrates: "EraSwap整合了所有来自DeFi和CEX认证的金融服务，通过智能推荐让大众更容易使用。用户可以在多个链之间切换，高效地跨不同链兑换顶级加密货币。",
        AbundantWeb3: "丰富的Web3社交功能",
        Encryptedchats: "加密聊天、群聊、智能群组推荐、代币控制组、DAO管理职能和增强的社会网络",
        Asuperspace: " 集Web3产业、咖啡、潮流品牌于一体的超级空间，为Web3企业提供活动、展览空间，也为个人提供办公、社交空间。 ",
        AnofflineWeb3: "传统群体的线下Web3入口， 打造可视化的Web3空间。 ",
        Service: "服务",
        Trendydrinks: "咖啡等潮流饮品、社交空间、潮流品牌销售、沙龙路演、品牌演示文稿。",
        Content: "内容",
        Resourceconnections: "资源连接、教育培训、业务咨询、产品体验、技术讨论等。",
    },
    about: {
        Onboarding: '带领10亿用户进入Web3 ',
        Explore: '探索CyberDAO的力量 ',
        CyberDAO: 'CYBERDAO是一个基于Proof of Devotion(POD)机制的DAO组织，致力于推动Web2用户向Web3时代的迁移。 ',
        Cultivate: "培育",
        outstanding: "Web3精英",
        CyberDAOprovids: "CyberDAO汇聚顶级资源——Web3专家、创新商业模式、运营经验。为成员提供了一个丰富的生态系统来学习和支持Web3。成员可以第一手接触新的Web3技术、产品和模式。 ",
        Lead: '带领',
        millionusers: " 亿用户",
        tomigrate: " 迁移到Web3   ",
        CyberDAOnurtures: "CyberDAO通过学习、经验和导师制度，引导Web2用户成为Web3的建设者和参与者，来培养Web3人才。",
        Build: "建立全球性的开放式",
        openWeb3: "Web3.0",
        businessecosystem: "商业生态系统",
        CyberDAObuilds: 'CyberDAO建立了一个开放的协作生态系统，以促进各行业对Web3的采用,获得更多权益。成员共同努力抓住Web3的机遇。 ',
        Themainentitys: "CyberDAO主要实体CyberHash总部位于新加坡，",
        isbased: " 已完成A轮2000万美元融资。",
        inSeries: " ",
        FinancialInfo: "融资信息",
        Themainentity: " CyberDAO 的主要实体“CyberHash Limited”位于新加坡。A轮融资由Lemon资本领投，投资额为2000万美元。",
        Themainentitys1: " CyberDAO 的主要实体“CyberHash Limited”位于新加坡。A轮融资由Lemon资本领投，投资额为2000万美元。",
        Advisors: "CyberHash的顾问",
        Alex: "Alex Tapscott",
        Kevin: "Kevin Kelly",
        Steve: "Steve Wozniak",
        Clark: "Clark Barrett",
        Partners: '合作伙伴 ',
        CyberHashrecently: "CyberHash最近向a16z等机构提出了其技术白皮书,并获得了a16z等机构的投资。这些投资包括其技术协议解决方案的产出。",
        TechChina: "TechChina报道 ",
        CyberDAOpartners: "CyberDAO与斯坦福大学合作开发了ABC区块链系统。ABC通过改进吞吐量和AI增强智能合约来创新区块链技术。技术白皮书阐述了ABC在区块链、存储、网络、框架和加密等方面的进步。",
        ReadMore: "更多信息",
        JointheCommunity: '加入社群',
        Learnmoreabout: "了解更多关于CyberDAO的信息，与团队和社区中的其他人交流，并对去中心化组织的未来发展发表您的观点。",
        Recent: "最近动态",
        CYBERDAO: "CYBERDAO",
        NFTIS: " NFT IS  ",
        LAUNCHING: "LAUNCHING",
    },
    v2: {
        navbar: {
            home: "CyberDAO",
            project: "项目孵化",
            govern: "治理",
            equity: "权益",
            joinUs: "加入我们",
            launchPad: "LaunchPad",
        },
        home: {
            sub1: "致力于投研孵化Web3优质项目以及从业者，共同构建全球化开放的Web3商业生态，助力DAO成员捕获Web3.0时代的发展红利。",
            sub2: "CyberCoin($CC)为CyberDAO的治理代币，DAO成员可依据个人CC持有量在生态中获取不同等级的权益以及治理权重。",
            totalStaking: "当前质押总量",
            totalLink: "当前链接数量",
            milestone: "里程碑事件",
            year2021: "2021年",
            year2021Describe: "成立",
            year2021Detail1: "主体CyberHash注册于新加坡。",
            year2021Detail2: "创始团队TechDAO成员均来自TikTok、Lark、Tencent、AWS、IBM等。",
            year2021Detail3: "完成2000万美元A轮融资，由Musically 联合创始人朱骏 (Alex Zhu) 领投，柠檬资本注资。",
            year2022: "2022年",
            year2022Describe: "探索",
            year2022Detail1: "与A16Z机构建立合作，并为其提供技术协议相关解决方案",
            year2022Detail2: "与斯坦福大学达成合作，共同开发 ABC 区块链系统。",
            year2022Detail3: "Alex Tapscott 加入顾问团队",
            year2022Detail4: "Kevin Kelly 加入顾问团队",
            year2022Detail5: "Steve Wozniak 加入顾问团队",
            year2022Detail6: "Clark Barrett 加入顾问团队",
            year2023: "2023年",
            year2023Describe: "发展",
            year2023Detail1: "POD 空间协议上线",
            year2023Detail2: "CyberFIL 存储项目质押开启",
            year2023Detail3: "CyberZodiac NFT 上线",
            year2023Detail4: "CyberPlanet GameFi 项目研发开启",
            year2023Detail5: "Web3Space 空间实体咖啡厅旗舰店落成",
            year2023Detail6: "Era SocialFi 项目研发开启",
            year2024: "2024年",
            year2024Describe: "爆发",
            year2024Detail1: "CyberDAO 成员权益升级",
            year2024Detail2: "Era 超级流量计划正式上线，并于香港嘉年华官方露出。",
            year2024Detail3: "MatrixFund 加密基金成立",
            year2024Detail4: "Web3Space 连锁计划开启",
            year2024Detail5: "CyberDAO 全国行开启",
        },
        project: {
            title: "项目孵化",
            cyberFil: "CyberFIL",
            cyberFilDescribe: "CyberFIL是专业的FIL存储质押服务商，同时也具备分布式存储节点设计、搭建、管理及集群化运作的全栈服务能力，让每一位用户都可以轻松拥有存力。",
            era: "ERA",
            eraDescribe: "ERA是一款开源的去中心化Web3社交平台，用户可通过DID数字身份来打造自己的社交影响力。平台采用POP人格证明防止女巫攻击，构建开放的全球真实用户网络，致力成为未来亿万用户的Web3社交选择。",
            matrixFund: "MatrixFund",
            matrixFundDescribe: "MatrixFund是一家 Web3创新基金，由头部加密基金Bitwise担任顾问、Coinbase Custody Trust担任基金托管、并为专业基金管理团队代管理，其基金管理规模达3亿美元。",
            cyberPlanet: "CyberPlanet",
            cyberPlanetDescribe: "CyberPlanet为Cyber Zodiac NFT的主要应用场景，持有NFT用户可在各类Web3游戏中获得不同权益，如：游戏积分、道具、模式开启权限等等。Cyber Planet Arcade为Cyber DAO成员提供另一种娱乐化的社交可能，营造Play 2 Earn氛围。",
            web3Space: "Web3Space",
            web3SpaceDescribe: "Web3Space为Web3行业资源俱乐部，专注于组织承办各类行业活动。Web3 从业者与爱好者既可通过 Web3Space 线下实体空间开展社交活动，也可收听参与线上推特Space 节目《Web3夜聊YEAH！》了解行业热点信息。",
        },
        govern: {
            title: "赛博空间协议",
            describe1: "为CyberDAO的治理协议，首次采用PoD (贡献度证明，Proof of Devotion)共识机制，通过建立好友链路，共同构建去中心化社交网络图谱，将分层社交互动转变为 DeFi 共识，彻底改变市场。",
            describe2: "生态中的各个角色，都可通过其社会行动积累并扩大共识网络影响力，并依此获得相应代币激励，即 $CC 激励。作为CyberDAO的价值载体 $CC，将会随着生态内流量价值的提高而不断增值。",
            issueTitle: "$CC发行量",
            issueDescribe: "CyberCoin总发行量为1亿枚",
            outputTitle: "$CC产出量",
            outputAllotTitle: "$CC产出分配",
            outputAllotDescribe: "每日产出将依据全网用户 CP 值占比进行分配。CP 值大小将由 CC 质押量、质押时长、链接用户数量、CC 实时价值等多维度因素共同决定，可通过 CP 值计算器计算。作为 CyberDAO 的价值载体，$CC 的价值依托不仅是 Cyber 生态本身的成长，同样也代表着 CyberDAO 所投研孵化的优质项目权益与价值标的，蕴含着无限能量。",
            proposalTitle: "治理提案",
            proposalMore: "了解更多",
        },
        equity: {
            memberTitle: "成员权益",
            voteRight: "投票权",
            proposalRight: "提案权",
            subDaoRight: "可成立子DAO",
            committeeRight: "治理委员会资格",
            memberRemark: "注：成员条件将根据 CC 价格变化与发展需求不定期做出调整（由治理委员会决议）",
            specialTitle: "特殊权益",
            specialRight1: "DAO 成员可享受最高权限 50% 的收益分成。",
            specialRight2: "DAO 成员可自动成为 ERA 大使，且具有 ET 积分兑换代币优先权。",
            specialRight3: "DAO 成员可持续获得 Web3 Space 咖啡券与现金券。S 级以上成员可获得活动嘉宾席位与路演机会。",
            specialRight4: "METF 基金每季度收益结算的 20% 将用于回购 CC / DAO 成员分红，具体用途由投票决定；且M级以上成员可成为大使，获取业务发展激励。",
            specialRight5: "Launch Pad 第一期：X-Cart，基于比特币为图腾的数字化潮牌，DAO 成员可获取白名单与 NFT 资格。",
        },
        joinUs: {
            title1: "加入我们",
            title2: "合作伙伴",
            daoLevel: "成员等级",
            stakingAmount: "质押数量",
            stakingDay: "质押时长",
            stakingNft: "NFT",
            stakingRightTitle: "权益",
            stakingRightC: "投票",
            stakingRightM: "投票/提案",
            stakingRightS: "投票/提案/子DAO",
            stakingRightX: "治理委员会资格",
            lemonCapital: "柠檬资本",
            stanfordUniversity: "斯坦福大学",
            advisoryTeam: "顾问团队",
            community: "社区",
            communityDesc: "了解更多关于CyberDAO的信息，与团队和社区中的其他人交流，并对去中心化组织的未来发展发表您的观点。",
        },
        launchPad: {
            title: "第一期：XCart",
            sub1: "XCart是全球首个以“BTC图腾文化”建立的Web3原生潮牌，创造独一无二RWA数字化商品，展现记录Web3 Builder的生活方式。并利用NFC芯片技术与区块链网络相连，形成虚实产品的唯一性证明，体验链上智能合约的数据交互，让每一件RWA商品基于NFC芯片都具备链上资产存储交互。",
            sub2: "想象自由的奢华，是Web3界的首个潮流领导者，专注垂直于RWA赛道对Crypto in real life的创新诠释。",
        },
    },
}
